 <template>
  <div>
    <CModal color="danger" :show="deleteModal">
      <template v-slot:header>
        <h5 class="modal-title">Delete Brand</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(deleteModal)"
        >
          ×
        </button>
      </template>
      Do you want to delete "<b>{{ brand ? brand.name : null}}</b>"?
      <template v-slot:footer>
        <CButton color="secondary" @click="closeModal(deleteModal)"
          >Cancel</CButton
        >

        <CButton color="secondary" class="px-4" disabled v-if="isSaving">
          <CSpinner grow size="sm" /> Deleting..
        </CButton>
        <CButton color="danger" @click="deleteBrand(brand)" v-else>
          Delete Brand
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BrandDelete",
  computed: {
    ...mapState({
      deleteModal: (state) => state.brands.deleteModal,
      brand: (state) => state.brands.deleteBrand,
      isSaving: (state) => state.brands.isSaving,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("brands/DELETE_MODAL", { bool: bool, brand: null });
    },
    deleteBrand(brand) {
      this.$store.dispatch("brands/destroyBrand", { 
        brand: brand
      });
    },
  },
};
</script>